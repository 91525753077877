// src/context/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { loginUser, logoutUser, registerUser } from '../services/api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        // Retrieve user from localStorage, if it exists
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : null;
    });

    useEffect(() => {
        // Store user in localStorage whenever it changes
        if (user) {
            localStorage.setItem('user', JSON.stringify(user));
        } else {
            localStorage.removeItem('user');
        }
    }, [user]);

    const login = async (email, password) => {
        try {
            const response = await loginUser({ email, password });
            console.log('Login response:', response.data); // Check what the backend is sending
            if (response.status === 200) {
                setUser(response.data.user);
	        console.log('User set in AuthContext:', response.data.user);
                localStorage.setItem('token', response.data.token); // Store token if using JWT
                return true;
            }
        } catch (error) {
            console.error('Login failed:', error);
            return false;
        }
    };

    const register = async (username, email, password) => {
        try {
            const response = await registerUser({ username, email, password });
            if (response.status === 201) {
                return true;
            }
        } catch (error) {
            console.error('Registration failed:', error);
            return false;
        }
    };

    const logout = async () => {
        try {
            await logoutUser();
            setUser(null);
            localStorage.removeItem('token'); // Remove token if using JWT
            localStorage.removeItem('user'); // Clear user from localStorage
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, register }}>
            {children}
        </AuthContext.Provider>
    );
};
