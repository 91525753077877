// SearchMembers.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { searchMembers, recordAttendance, fetchMeetings } from '../services/api';
import GenerateQrCode from './GenerateQrCode';

const SearchMembers = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [meetings, setMeetings] = useState([]);
    const [selectedMeeting, setSelectedMeeting] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const meetingsResponse = await fetchMeetings();
                setMeetings(meetingsResponse.data);
            } catch (error) {
                console.error('Error fetching meetings:', error);
            }
        };

        fetchData();
    }, []);

    const handleSearch = useCallback(async () => {
        try {
            const response = await searchMembers(query);
            setResults(response.data);
        } catch (error) {
            console.error('Error searching members:', error);
        }
    }, [query]);

    const handleKeyPress = useCallback((event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSearch();
        }
    }, [handleSearch]);

    useEffect(() => {
        const inputElement = inputRef.current;

        if (inputElement) {
            inputElement.addEventListener('keypress', handleKeyPress);
        }

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('keypress', handleKeyPress);
            }
        };
    }, [handleKeyPress]);

    const handleCheckboxChange = (memberId) => {
        setSelectedMembers((prevSelected) =>
            prevSelected.includes(memberId)
                ? prevSelected.filter((id) => id !== memberId)
                : [...prevSelected, memberId]
        );
    };

    const handleRecordAttendance = async () => {
        if (!selectedMeeting) {
            alert('Please select a meeting.');
            return;
        }

        try {
            const response = await recordAttendance(selectedMembers, selectedMeeting);
            alert(response.data.message);
            setSelectedMembers([]);
            setResults([]);
        } catch (error) {
            console.error('Error recording attendance:', error);
        }
    };

    return (
        <div>
            <h2>Search and Record Members Attendance</h2>
            <div>
                <select
                    value={selectedMeeting}
                    onChange={(e) => setSelectedMeeting(e.target.value)}
                >
                    <option value="">Select Meeting</option>
                    {meetings.map((meeting) => (
                        <option key={meeting.meeting_id} value={meeting.meeting_id}>
                            {meeting.meeting_name}
                        </option>
                    ))}
                </select>
            </div>
            <input
                type="text"
                ref={inputRef}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Enter partial surname"
            />
            <button onClick={handleSearch}>Search</button>
            <div>
                {results.map((member) => (
                    <div key={member.member_id}>
                        <input
                            type="checkbox"
                            checked={selectedMembers.includes(member.member_id)}
                            onChange={() => handleCheckboxChange(member.member_id)}
                        />
                        <label>
                            {member.first_name} {member.last_name} (Last check-in: {member.last_check_in || 'Never'})
                        </label>
                    </div>
                ))}
            </div>
            <button onClick={handleRecordAttendance}>Record Attendance</button>
            <GenerateQrCode />
        </div>
    );
};

export default SearchMembers;
