import React, { useState, useEffect } from 'react';
import QrReader from 'react-qr-scanner';
import { qrCheckIn, fetchMeetings } from '../services/api';

const QrCheckIn = () => {
    const [message, setMessage] = useState('');
    const [meetings, setMeetings] = useState([]);
    const [selectedMeeting, setSelectedMeeting] = useState('');
    const [cameras, setCameras] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const meetingsResponse = await fetchMeetings();
                setMeetings(meetingsResponse.data);
            } catch (error) {
                console.error('Error fetching meetings:', error);
                setMessage('Failed to load meetings.');
            }
        };

        fetchData();
        listAvailableCameras();
    }, []);

    const listAvailableCameras = async () => {
        try {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(device => device.kind === 'videoinput');

            setCameras(videoDevices);

            // Attempt to select the rear camera by default
            const rearCamera = videoDevices.find(device => device.label.toLowerCase().includes('back')) || videoDevices[0];
            if (rearCamera) {
                setSelectedCamera(rearCamera.deviceId);
            }
        } catch (error) {
            console.error('Error accessing cameras:', error);
            setMessage('Unable to access cameras.');
        }
    };

    const handleScan = async (data) => {
        if (data) {
            if (!selectedMeeting) {
                setMessage('Please select a meeting before scanning.');
                return;
            }

            try {
                // Extract the numeric member ID from the scanned QR code data
                const match = data.text.match(/Member Id:\s*(\d+)/i);
                if (!match) {
                    setMessage('Invalid QR code format.');
                    return;
                }

                const memberIdNumber = parseInt(match[1], 10);
                console.log('Scanned member ID:', memberIdNumber);

                const response = await qrCheckIn(memberIdNumber, parseInt(selectedMeeting, 10));
                const successMessage = response.data.message;
                setMessage(successMessage);

                // Show an alert for a successful check-in
               // window.alert(successMessage);
                //  setMessage(successMessage);

                // Reset for new scan
                resetScanner();
            } catch (error) {
                if (error.response) {
                    console.error('API Error:', error.response.data);
                    setMessage(error.response.data.error || 'Error checking in with QR code');
                } else {
                    console.error('Unexpected Error:', error);
                    setMessage('Unexpected error occurred during check-in.');
                }
            }
        }
    };

    const handleError = (error) => {
        console.error('QR Scan Error:', error);
        setMessage('Error scanning QR code');
    };

    const resetScanner = () => {
        // Clear message for next scan
        setMessage('');
        // Additional states can be reset here if needed
    };

    const previewStyle = {
        height: 290,
        width: 420,
    };

    return (
        <div>
            <h2>QR Code Check-In</h2>
            <div>
                <select
                    value={selectedMeeting}
                    onChange={(e) => setSelectedMeeting(e.target.value)}
                >
                    <option value="">Select Meeting</option>
                    {meetings.map((meeting) => (
                        <option key={meeting.meeting_id} value={meeting.meeting_id}>
                            {meeting.meeting_name}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <select
                    value={selectedCamera}
                    onChange={(e) => setSelectedCamera(e.target.value)}
                >
                    {cameras.map((camera) => (
                        <option key={camera.deviceId} value={camera.deviceId}>
                            {camera.label || `Camera ${camera.deviceId}`}
                        </option>
                    ))}
                </select>
            </div>
            <QrReader
                delay={300}
                style={previewStyle}
                onError={handleError}
                onScan={handleScan}
                constraints={{
                    video: {
                        deviceId: selectedCamera ? { exact: selectedCamera } : undefined,
                    },
                }}
            />
            <p>{message}</p>
        </div>
    );
};

export default QrCheckIn;
