import React, { useEffect, useState } from 'react';
import { fetchMembers_reg } from '../services/api';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import moment from 'moment';
import './MemberList.css';

const MemberList = () => {
    const [members, setMembers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [meetings, setMeetings] = useState([]);
    const [types, setTypes] = useState([]);  // Types for dropdown
    const [departments, setDepartments] = useState([]);  // Departments for dropdown
    const [selectedMeeting, setSelectedMeeting] = useState(null);
    const [selectedType, setSelectedType] = useState(null);  // Selected type filter
    const [selectedDepartment, setSelectedDepartment] = useState(null);  // Selected department filter
    const [sortBy, setSortBy] = useState('created_at');
    const [sortOrder, setSortOrder] = useState('desc');

    useEffect(() => {
        const fetchMembers = async () => {
            try {
                const response = await fetchMembers_reg(currentPage, perPage, selectedMeeting, selectedType, selectedDepartment, sortBy, sortOrder);
                setMembers(response.data.members);
                setTotalPages(response.data.pagination.total_pages);
                setMeetings(response.data.meetings);
                setTypes(response.data.types);  // Fetch types
                setDepartments(response.data.departments);  // Fetch departments
            } catch (error) {
                console.error("There was an error fetching the members!", error);
            }
        };

        fetchMembers();
    }, [currentPage, perPage, selectedMeeting, selectedType, selectedDepartment, sortBy, sortOrder]);

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePerPageChange = (event) => {
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };

    const handleMeetingChange = (event) => {
        setSelectedMeeting(event.target.value === 'all' ? null : event.target.value);
        setCurrentPage(1);
    };

    const handleTypeChange = (event) => {
        setSelectedType(event.target.value === 'all' ? null : event.target.value);
        setCurrentPage(1);
    };

    const handleDepartmentChange = (event) => {
        setSelectedDepartment(event.target.value === 'all' ? null : event.target.value);
        setCurrentPage(1);
    };

    const handleSortChange = (event) => {
        setSortBy(event.target.value);
        setCurrentPage(1);
    };

    const handleSortOrderChange = (event) => {
        setSortOrder(event.target.value);
        setCurrentPage(1);
    };

    // Find the max attendance count to calculate the gradient
    const maxAttendance = Math.max(...members.map(member => member.attendance_count), 0);

    // Function to calculate the color based on attendance count
    const getColor = (attendance) => {
        const red = Math.min(255, Math.floor((1 - (attendance / maxAttendance)) * 255));
        const green = Math.min(255, Math.floor((attendance / maxAttendance) * 255));
        return `rgb(${red}, ${green}, 0)`;
    };

    // Prepare data for the bar chart
    const barChartData = members.map(member => ({
        name: `${member.first_name} ${member.last_name}`,
        attendance: member.attendance_count,
        registeredOn: moment(member.created_at).format('ddd, DD MMM YYYY HH:mm:ss [GMT]'),
        lastAttendance: member.last_attendance_time ? moment(member.last_attendance_time).format('ddd, DD MMM YYYY HH:mm:ss [GMT]') : 'N/A',
        fill: getColor(member.attendance_count)  // Assign color to each bar
    }));

    return (
        <div className="member-list-container">
            <h2>Member List</h2>

            {/* Filter by Meeting */}
            <div className="meeting-filter">
                <label htmlFor="meeting-filter">Filter by Meeting:</label>
                <select id="meeting-filter" onChange={handleMeetingChange} value={selectedMeeting || 'all'}>
                    <option value="all">All Meetings</option>
                    {meetings.map(meeting => (
                        <option key={meeting.meeting_id} value={meeting.meeting_id}>
                            {meeting.meeting_name}
                        </option>
                    ))}
                </select>
            </div>

            {/* Filter by Type */}
            <div className="type-filter">
                <label htmlFor="type-filter">Filter by Type:</label>
                <select id="type-filter" onChange={handleTypeChange} value={selectedType || 'all'}>
                    <option value="all">All Types</option>
                    {types.map(type => (
                        <option key={type.type_id} value={type.type_id}>
                            {type.type_name}
                        </option>
                    ))}
                </select>
            </div>

            {/* Filter by Department */}
            <div className="department-filter">
                <label htmlFor="department-filter">Filter by Department:</label>
                <select id="department-filter" onChange={handleDepartmentChange} value={selectedDepartment || 'all'}>
                    <option value="all">All Departments</option>
                    {departments.map(department => (
                        <option key={department.department_id} value={department.department_id}>
                            {department.department_name}
                        </option>
                    ))}
                </select>
            </div>

            {/* Sorting */}
            <div className="sort-controls">
                <label htmlFor="sort-by">Sort by:</label>
                <select id="sort-by" value={sortBy} onChange={handleSortChange}>
                    <option value="first_name">First Name</option>
                    <option value="last_name">Last Name</option>
                    <option value="attendance_count">Attendance Count</option>
                    <option value="created_at">Registered on</option>
                    <option value="last_attendance">Last Time in</option>
                </select>

                <label htmlFor="sort-order">Order:</label>
                <select id="sort-order" value={sortOrder} onChange={handleSortOrderChange}>
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                </select>
            </div>

            {/* Pagination */}
            <div className="pagination-controls">
                <button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
            </div>

            {/* Items per page */}
            <div className="per-page-selector">
                <label htmlFor="per-page">Items per page:</label>
                <select id="per-page" value={perPage} onChange={handlePerPageChange}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={40}>40</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={200}>200</option>
                </select>
            </div>

            {/* Bar Chart */}
            <div className="attendance-chart-container">
                <h3>Attendance Chart</h3>
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={barChartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip 
                            content={({ payload }) => {
                                if (payload && payload.length) {
                                    const data = payload[0].payload;
                                    return (
                                        <div className="custom-tooltip">
                                            <p><strong>{data.name}</strong></p>
                                            <p>Attendance: {data.attendance}</p>
                                            <p>Registered on: {data.registeredOn}</p>
                                            <p>Last Time in: {data.lastAttendance}</p>
                                        </div>
                                    );
                                }
                                return null;
                            }}
                        />
                        <Legend />
                        <Bar dataKey="attendance" fill={({ payload }) => payload.fill} />
                    </BarChart>
                </ResponsiveContainer>
            </div>

            {/* Members Table */}
            <div className="table-responsive">
                <table className="member-table">
                    <thead>
                        <tr>
                            <th>Serial No.</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Phone Number</th>
                            <th>Registered on</th>
                            <th>Attendance Count</th>
                            <th>Last Time in</th>
                        </tr>
                    </thead>
                    <tbody>
                        {members.map((member, index) => (
                            <tr key={member.member_id}>
                                <td>{(currentPage - 1) * perPage + index + 1}</td>
                                <td>{member.first_name}</td>
                                <td>{member.last_name}</td>
                                <td>{member.phone_number}</td>
                                <td>{moment(member.created_at).format('ddd, DD MMM YYYY HH:mm:ss [GMT]')}</td>
                                <td>{member.attendance_count}</td>
                                <td>{member.last_attendance_time ? moment(member.last_attendance_time).format('ddd, DD MMM YYYY HH:mm:ss [GMT]') : 'N/A'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default MemberList;
