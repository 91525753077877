import React, { useState, useEffect } from 'react';
import { fetchTypes, fetchMeetings, fetchAttendanceStatuses, fetchFilteredData } from '../services/api';

const FilterComponent = () => {
    const [types, setTypes] = useState([]);
    const [meetings, setMeetings] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [selectedType, setSelectedType] = useState('All');
    const [selectedMeeting, setSelectedMeeting] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [members, setMembers] = useState([]);

    useEffect(() => {
        fetchTypesData();
        fetchMeetingsData();
        fetchStatusesData();
    }, []);

    const fetchTypesData = async () => {
        try {
            const response = await fetchTypes();
            setTypes(response.data);
        } catch (error) {
            console.error("Error fetching types:", error);
        }
    };

    const fetchMeetingsData = async () => {
        try {
            const response = await fetchMeetings();
            setMeetings(response.data);
        } catch (error) {
            console.error("Error fetching meetings:", error);
        }
    };

    const fetchStatusesData = async () => {
        try {
            const response = await fetchAttendanceStatuses();
            setStatuses(response.data);
        } catch (error) {
            console.error("Error fetching statuses:", error);
        }
    };

    const fetchFilteredDataHandler = async () => {
        try {
            const response = await fetchFilteredData(selectedMeeting, selectedType, selectedStatus, selectedDate);
            setMembers(response.data);
        } catch (error) {
            console.error("Error fetching filtered data:", error);
        }
    };

    return (
        <div>
            <div>
                <label>Type:</label>
                <select onChange={(e) => setSelectedType(e.target.value)}>
                    {types.map((type) => (
                        <option key={type.type_id} value={type.type_id}>{type.type_name}</option>
                    ))}
                </select>
            </div>
            <div>
                <label>Meeting:</label>
                <select onChange={(e) => setSelectedMeeting(e.target.value)}>
                    {meetings.map((meeting) => (
                        <option key={meeting.meeting_id} value={meeting.meeting_id}>{meeting.meeting_name}</option>
                    ))}
                </select>
            </div>
            <div>
                <label>Status:</label>
                <select onChange={(e) => setSelectedStatus(e.target.value)}>
                    <option value="All">All</option>
                    <option value="Absent">Absent</option>
                    {statuses.map((status, index) => (
                        <option key={index} value={status}>{status}</option>
                    ))}
                </select>
            </div>
            <div>
                <label>Date:</label>
                <input type="date" onChange={(e) => setSelectedDate(e.target.value)} />
            </div>
            <button onClick={fetchFilteredDataHandler}>Fetch Data</button>
            <table>
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Phone Number</th>
                        <th>Email</th>
                        <th>Address</th>
			<th>Post Code</th>
                    </tr>
                </thead>
                <tbody>
                    {members.map((member, index) => (
                        <tr key={index}>
                            <td>{member.first_name}</td>
                            <td>{member.last_name}</td>
                            <td>{member.phone_number}</td>
                            <td>{member.email}</td>
                            <td>{member.address}</td>
			   <td>{member.post_code}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default FilterComponent;
