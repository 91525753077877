// GenerateQrCode.js
import React, { useState, useEffect } from 'react';
import { fetchMembers, generateQrCode } from '../services/api';

const GenerateQrCode = () => {
    const [members, setMembers] = useState([]);
    const [selectedMember, setSelectedMember] = useState('');
    const [qrCode, setQrCode] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchMembers();
                setMembers(response.data);
            } catch (error) {
                console.error('Error fetching members:', error);
            }
        };

        fetchData();
    }, []);

    const handleGenerateQr = () => {
        if (selectedMember) {
            const qrUrl = generateQrCode(selectedMember);
            setQrCode(qrUrl);
        }
    };

    return (
        <div>
            <h2>Generate QR Code for Members</h2>
            <div>
                <select
                    value={selectedMember}
                    onChange={(e) => setSelectedMember(e.target.value)}
                >
                    <option value="">Select Member</option>
                    {members.map((member) => (
                        <option key={member.member_id} value={member.member_id}>
                           {member.last_name}  {member.first_name} {member.member_id}
                        </option>
                    ))}
                </select>
                <button onClick={handleGenerateQr}>Generate QR</button>
            </div>
            {qrCode && (
                <div>
                    <h3>QR Code</h3>
                    <img src={qrCode} alt="QR Code" />
                </div>
            )}
        </div>
    );
};

export default GenerateQrCode;
