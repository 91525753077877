import React, { useState, useEffect } from 'react';
import { fetchMeetings, fetchAttendanceAnalysis } from '../services/api';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
//import {Chart, defaults} from 'chart.js/auto}';
//defaults.maintainAspectRatio = false;
//defaults.responsive = true;

const AttendanceAnalysis = () => {
    const [meetings, setMeetings] = useState([]);
    const [selectedMeeting, setSelectedMeeting] = useState('');
    const [attendanceData, setAttendanceData] = useState(null);
    const [error, setError] = useState('');

    // Load meetings initially
    useEffect(() => {
        const loadMeetings = async () => {
            try {
                const response = await fetchMeetings();
                setMeetings(response.data);
            } catch (error) {
                console.error('Error fetching meetings:', error);
            }
        };
        loadMeetings();
    }, []);

    const handleMeetingChange = (event) => {
        setSelectedMeeting(event.target.value);
        setError('');
    };

    // Fetch attendance analysis every 5 seconds when a meeting is selected
    useEffect(() => {
        let intervalId;
        const fetchAnalysis = async () => {
            try {
                if (selectedMeeting) {
                    const response = await fetchAttendanceAnalysis(selectedMeeting);
                    setAttendanceData(response.data);
                    setError('');
                }
            } catch (error) {
                console.error('Error fetching attendance analysis:', error);
                if (error.response && error.response.data && error.response.data.error === 'Not enough data for comparison') {
                    setAttendanceData({
                        meeting_names: [selectedMeeting],
                        attendance_counts: [{
                            'meeting_name': selectedMeeting,
                            'meeting_date': '',
                            'attendance_count': 0
                        }]
                    });
                } else {
                    setError('Error fetching attendance analysis: ' + error.response.data.error);
                }
            }
        };

        if (selectedMeeting) {
            fetchAnalysis(); // Initial fetch
            intervalId = setInterval(fetchAnalysis, 5000); // Fetch every 5 seconds
        }

        // Cleanup function to clear the interval
        return () => clearInterval(intervalId);
    }, [selectedMeeting]);

    const data = {
        labels: attendanceData ? attendanceData.meeting_names : [],
        datasets: [
            {
                label: 'Total Attendance for the selected Period and Meeting.',
                data: attendanceData ? attendanceData.attendance_counts.map(ac => ac.attendance_count) : [],
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 3
            }
        ]
    };

    return (
        <div>
            <h2>Attendance Analysis</h2>
            <div>
                <label>Select Meeting: </label>
                <select value={selectedMeeting} onChange={handleMeetingChange}>
                    <option value="">-- Select a Meeting --</option>
                    {meetings.map(meeting => (
                        <option key={meeting.meeting_id} value={meeting.meeting_name}>
                            {meeting.meeting_name}
                        </option>
                    ))}
                </select>
            </div>
            {error && <p style={{color: 'red'}}>{error}</p>}
            <div>
                {attendanceData && <Bar data={data} />}
            </div>
        </div>
    );
};

export default AttendanceAnalysis;
