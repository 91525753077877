import React, { useState, useEffect } from 'react';
import { searchMembers, fetchMemberById, updateMember, fetchDepartments, fetchUnits, fetchTypes } from '../services/api';

const UpdateMember = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const [formData, setFormData] = useState({});
    const [departments, setDepartments] = useState([]);
    const [units, setUnits] = useState([]);
    const [types, setTypes] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const departmentsResult = await fetchDepartments();
            setDepartments(departmentsResult.data);
            const unitsResult = await fetchUnits();
            setUnits(unitsResult.data);
            const typesResult = await fetchTypes();
            setTypes(typesResult.data);
        };

        fetchData();
    }, []);

    const handleSearch = async () => {
        try {
            const response = await searchMembers(query);
            setResults(response.data);
        } catch (error) {
            console.error('Error searching members:', error);
        }
    };

    const handleSelectMember = async (memberId) => {
        try {
            const response = await fetchMemberById(memberId);
            setSelectedMember(response.data);
            setFormData(response.data);
        } catch (error) {
            console.error('Error fetching member data:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateMember(selectedMember.member_id, formData);
            alert('Member updated successfully!');
        } catch (error) {
            console.error('Error updating member:', error);
        }
    };

    return (
        <div>
            <h2>Search and Update Member</h2>
            <div>
                <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Enter partial surname"
                />
                <button onClick={handleSearch}>Search</button>
            </div>
            <div>
                {results.map((member) => (
                    <div key={member.member_id}>
                        <button onClick={() => handleSelectMember(member.member_id)}>
                            {member.first_name} {member.last_name}
                        </button>
                    </div>
                ))}
            </div>
            {selectedMember && (
                <form onSubmit={handleSubmit}>
                    <input type="text" name="first_name" value={formData.first_name || ''} onChange={handleChange} placeholder="First Name" required />
                    <input type="text" name="last_name" value={formData.last_name || ''} onChange={handleChange} placeholder="Last Name" required />
                    <input type="email" name="email" value={formData.email || ''} onChange={handleChange} placeholder="Email"  />
                    <input type="text" name="phone_number" value={formData.phone_number || ''} onChange={handleChange} placeholder="Phone Number"  />
                    <input type="text" name="address" value={formData.address || ''} onChange={handleChange} placeholder="Address" />
                    <input type="text" name="post_code" value={formData.post_code || ''} onChange={handleChange} placeholder="Post Code"  />
                    <div>
                        <label htmlFor="birthdate">Birthdate:</label>
                        <input type="date" name="birthdate" value={formData.birthdate || ''} onChange={handleChange}  />
                    </div>
                    <select name="department_id" value={formData.department_id || ''} onChange={handleChange} >
                        <option value="">Select Department</option>
                        {departments.map((department) => (
                            <option key={department.department_id} value={department.department_id}>
                                {department.department_name}
                            </option>
                        ))}
                    </select>
                    <select name="unit_id" value={formData.unit_id || ''} onChange={handleChange} >
                        <option value="">Select Unit</option>
                        {units.map((unit) => (
                            <option key={unit.unit_id} value={unit.unit_id}>
                                {unit.unit_name}
                            </option>
                        ))}
                    </select>
                    <select name="type_id" value={formData.type_id || ''} onChange={handleChange} >
                        <option value="">Select Type</option>
                        {types.map((type) => (
                            <option key={type.type_id} value={type.type_id}>
                                {type.type_name}
                            </option>
                        ))}
                    </select>
                    <input type="text" name="other_details" value={formData.other_details || ''} onChange={handleChange} placeholder="Other Details" />
                    <input type="text" name="middle_name" value={formData.middle_name || ''} onChange={handleChange} placeholder="Middle Name" />
                    <input type="text" name="city" value={formData.city || ''} onChange={handleChange} placeholder="City"  />
                    <select name="relationship" value={formData.relationship || ''} onChange={handleChange} >
                        <option value="">Select Relationship</option>
                        <option value="Head">Head</option>
                        <option value="Spouse">Spouse</option>
                        <option value="Child">Child</option>
                        <option value="Other">Other</option>
                    </select>
                    <select name="gender" value={formData.gender || ''} onChange={handleChange} >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Prefer not to say">Prefer not to say</option>
                    </select>
                    <button type="submit">Update Member</button>
                </form>
            )}
        </div>
    );
};

export default UpdateMember;

